import { useState, useEffect, useCallback, useRef } from 'react';
import { keepAlive } from '../api/sessionManagement';

const useChatSession = ({
  sessionId,
  inactivityTimeout,
  pingInterval,
  onInactive,
  initialDelay,
  activityEvents,
}) => {
  const [isActive, setIsActive] = useState(true);
  const [lastActivityTime, setLastActivityTime] = useState(Date.now());
  const pingIntervalId = useRef(null);
  const initialTimeoutId = useRef(null);

  // Handle activity
  const handleActivity = useCallback(() => {
    setLastActivityTime(Date.now());
    setIsActive(true);
  }, []);


  // Reset timer
  const resetTimer = useCallback(() => {
    if (initialTimeoutId.current) {
      clearTimeout(initialTimeoutId.current);
      initialTimeoutId.current = null;
    }
    if (pingIntervalId.current) {
      clearInterval(pingIntervalId.current);
      pingIntervalId.current = null;
    }
  }, []);

  // Ping server
  useEffect(() => {
    resetTimer();
    if (!isActive) {
      return;
    }

    console.log('Start ping interval');
    
    const pingServer = async () => {
      try {
        console.log('Ping');
        await keepAlive(sessionId);
      } catch (error) {
        console.error('Ping error:', error);
      }
    };

    initialTimeoutId.current = setTimeout(() => {
      if (isActive) {
        pingServer();
        pingIntervalId.current = setInterval(pingServer, pingInterval);
      }
    }, initialDelay);

    return resetTimer;
  }, [sessionId, pingInterval, isActive, initialDelay, resetTimer]);

  // Activity event listeners
  useEffect(() => {
    const events = activityEvents;
    events.forEach(event => window.addEventListener(event, handleActivity));
    return () => {
      events.forEach(event => window.removeEventListener(event, handleActivity));
    };
  }, [handleActivity, activityEvents]);

  // Inactivity check
  useEffect(() => {
    const checkInactivity = () => {
      const timeSinceLastActivity = Date.now() - lastActivityTime;
      if (timeSinceLastActivity >= inactivityTimeout) {
        setIsActive(false);
        resetTimer();
        if (onInactive) onInactive();
      }
    };

    const inactivityCheckId = setInterval(checkInactivity, 60000);

    return () => clearInterval(inactivityCheckId);
  }, [lastActivityTime, inactivityTimeout, onInactive, resetTimer]);

  // End session
  const endSession = useCallback(() => {
    console.log('End session');
    setIsActive(false);
    resetTimer();
    if (onInactive) onInactive();
  }, [onInactive, resetTimer]);

  return { isActive, endSession };
};

export default useChatSession;